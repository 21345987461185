import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "./styles/ContactForm.css";
import { getAppLang } from "../redux/selectors.js";
import appData from "../data/appData.json";
import ContactFormField from "./ContactFormField.jsx";
import ContactFormArea from "./ContactFormArea.jsx";
import { sendContactEmail } from "../email.js";

const ContactForm = ({ lang, openError, openSuccess, setLoading }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: ""
  });

  const validateForm = () => {
    const formErrors = {};
    let valid = true;

    if (firstName === "") {
      valid = false;
      formErrors.firstName = lang === "en"
        ? "First Name is required"
        : "Le Prénom est requis";
    }
    if (lastName === "") {
      valid = false;
      formErrors.lastName = lang === "en"
        ? "Last Name is required"
        : "Le Nom est requis";
    }
    if (email === "") {
      valid = false;
      formErrors.email = lang === "en"
        ? "Email is required"
        : "Le Courriel est requis";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      valid = false;
      formErrors.email = lang === "en"
        ? "Email is invalid"
        : "Le Courriel est invalide";
    }
    if (subject === "") {
      valid = false;
      formErrors.subject = lang === "en"
        ? "Subject is required"
        : "Le Sujet est requis";
    }
    if (message === "") {
      valid = false;
      formErrors.message = lang === "en"
        ? "Message is required"
        : "Le Message est requis";
    }

    setErrors(formErrors);
    return valid;
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const emailSuccess = () => {
    clearForm();
    setLoading(false);
    openSuccess();
  };
  const emailError = () => {
    setLoading(false);
    openError();
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const messageData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        subject: subject,
        message: message
      };
      sendContactEmail(messageData, emailSuccess, emailError);

      setErrors({
        firstName: "", lastName: "", email: "", subject: "", message: ""
      });
    }
  };

  return (
    <div className="p-4">
      <div className="contact-row">
        <div className="contact-row-item-1">
          <ContactFormField
            label={appData?.contact?.form?.firstName[lang]}
            stateFunc={setFirstName}
            stateVal={firstName}
            error={errors.firstName}
            updateError={
              () => setErrors({ ...errors, firstName: "" })
            }
          />
        </div>
        <div className="contact-row-item-2">
          <ContactFormField
            label={appData?.contact?.form?.lastName[lang]}
            stateFunc={setLastName}
            stateVal={lastName}
            error={errors.lastName}
            updateError={
              () => setErrors({ ...errors, lastName: "" })
            }
          />
        </div>
      </div>
      <div className="contact-row">
        <div className="contact-row-item-1">
          <ContactFormField
            label={appData?.contact?.form?.email[lang]}
            stateFunc={setEmail}
            stateVal={email}
            error={errors.email}
            updateError={
              () => setErrors({ ...errors, email: "" })
            }
          />
        </div>
        <div className="contact-row-item-2">
          <ContactFormField
            label={appData?.contact?.form?.subject[lang]}
            stateFunc={setSubject}
            stateVal={subject}
            error={errors.subject}
            updateError={
              () => setErrors({ ...errors, subject: "" })
            }
          />
        </div>
      </div>
      <div className="contact-row">
        <ContactFormArea
          label="Message"
          stateFunc={setMessage}
          stateVal={message}
          error={errors.message}
          updateError={
            () => setErrors({ ...errors, message: "" })
          }
        />
      </div>
      <div className="contact-row">
        <div
          className="contact-form-button"
          onClick={
            (event) => submitForm(event)
          }
          onTouchEnd={
            (event) => submitForm(event)
          }
        >
          {appData?.contact?.form?.send[lang]}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
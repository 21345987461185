import {
  ERROR_ACTION,
  LOAD_DATA,
  LOADING_END,
  LOADING_START,
  TOGGLE_LANG,
  USER_REGISTER,
  USER_LOGIN,
  USER_LOGOUT,
  SESSION_DELETE,
  SESSION_ADD,
  SESSION_CONFIRM,
  SESSION_CANCEL,
  LOAD_SESSIONS,
} from "./actions.js";

const INITIAL_STATE = {
  loading: false,
  loaded: false,
  lang: "fr",
  user: null,
  data: {
    user: null,
    children: [],
    sessions: [],
  }
};

export const appReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      };

    case LOADING_END:
      return {
        ...state,
        loading: false,
        loaded: true
      };

    case LOAD_DATA:
      const { data } = payload;
      return {
        ...state,
        data
      };

    case LOAD_SESSIONS:
      const { sessions } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          sessions: {
            ...state.data.sessions,
            sessions
          }
        }
      };

    case TOGGLE_LANG:
      const { lang } = payload;
      return {
        ...state,
        lang: lang
      };

    case USER_REGISTER:
      const { newUser } = payload;
      return {
        ...state,
        user: newUser
      };

    case USER_LOGIN:
      const { user } = payload;
      return {
        ...state,
        user
      };

    case USER_LOGOUT:
      return {
        ...state,
        user: null
      };

    case ERROR_ACTION:
      return {
        ...state,
        error: payload.error
      };

    case SESSION_ADD:
      const { newSession } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          sessions: [...state.data.sessions, newSession]
        }
      };

    case SESSION_DELETE:
      const { oldSession } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          sessions: state.data.sessions.filter(
            session => session.id !== oldSession.id
          )
        }
      };

    case SESSION_CONFIRM:
      const { session } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          sessions: state.data.sessions.map(
            s => (s.id === session.id ? session : s)
          )
        }
      };

    case SESSION_CANCEL:
      const { canceledSession } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          sessions: state.data.sessions.map(
            s => (s.id === canceledSession.id ? canceledSession : s)
          )
        }
      };

    default:
      return state;
  };
};